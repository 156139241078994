import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../context/UserProvider';

export const ProtectedRoutes = () => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) {
    return <Navigate to="/logga-in" replace />;
  }

  return <Outlet />;
};
