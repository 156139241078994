import styled from '@emotion/styled';
import { MyRegistrationsMenu } from '../../../components/my-registrations/MyRegistrationsMenu';
import { HiddenOnMobile } from '../../../layout';
import { Outlet } from 'react-router-dom';

const Wrapper = styled.div``;

const Content = styled.div`
  padding: 10px;

  @media (min-width: 600px) {
    flex: 1;
    padding: 20px;
    margin-left: 300px;
    min-height: calc(100vh - 60px);
  }
`;

const MobileMenu = styled.div`
  display: none;
`;

export const MyRegistrationsPage = () => {
  return (
    <Wrapper>
      <MobileMenu>
        <MyRegistrationsMenu />
      </MobileMenu>
      <HiddenOnMobile>
        <MyRegistrationsMenu />
      </HiddenOnMobile>
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};
