import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './routes/Root';
import './index.css';
import { reportWebVitals } from './reportWebVitals';
import ReactGA from 'react-ga4';
import { AnalyticsWithConsent } from './components/main/AnalyticsWithConsent';
import { Navigation } from './navigation/Navigation';
import { paths } from './navigation/paths';
import { Home } from './routes/Home';
import { SearchResultPage } from './routes/SearchResultPage';
import { About } from './routes/About';
import { RegistrationDetails } from './routes/RegistrationDetails';
import { Contact } from './routes/Contact';
import { PrivatePolicy } from './routes/PrivatePolicy';
import { AdminPage } from './routes/admin/AdminPage';
import { EditCategoriesPage } from './routes/admin/EditCategoriesPage';
import { EditDataAttributesPage } from './routes/admin/EditDataAttributesPage';
import { EditFinancionsPage } from './routes/admin/EditFinancingsPage';
import { FortnoxStatusPage } from './routes/admin/FortnoxStatusPage';
import { EditCompaniesPage } from './routes/admin/EditCompaniesPage';
import { EditOwnersPage } from './routes/admin/EditOwnersPage';
import { EditPersonsPage } from './routes/admin/EditPersonsPage';
import { EditOwnershipPage } from './routes/admin/EditOwnershipPage';
import { EditRegistrationsPage } from './routes/admin/EditRegistrationsPage';
import { NewAdminRegistrationPage } from './routes/admin/NewAdminRegistrationPage';
import { EditUsersPage } from './routes/admin/EditUsersPage';
import { ProtectedRoutes } from './routes/protected/ProtectedRoutes';
import { FinancingsPage } from './routes/protected/FinancingsPage';
import { InsurancesPage } from './routes/protected/InsurancesPage';
import { LeasesPage } from './routes/protected/LeasesPage';
import { MyOwnersPage } from './routes/protected/MyOwnersPage';
import { MyProfilePage } from './routes/protected/MyProfilePage';
import { ChangeOfOwnershipCompletePage } from './routes/protected/ChangeOfOwnershipCompletePage';
import { ConfirmOfOwnershipCompletePage } from './routes/protected/ConfirmOfOwnershipCompletePage';
import { MyRegistrationsPage } from './routes/protected/registrations/MyRegistrationsPage';
import { ChangeOfOwnerRegistrationsPage } from './pages/my-registrations/ChangeOfOwnerRegistrations';
import { ChangeOfOwnershipPage } from './routes/protected/registrations/ChangeOfOwnershipPage';
import { ConfirmedRegistrationsPage } from './routes/protected/registrations/ConfirmedRegistrationsPage';
import { NewRegistrationByOwnerPage } from './routes/protected/registrations/NewRegistrationByOwnerPage';
import { OwnerOverviewPage } from './routes/protected/registrations/OwnerOverviewPage';
import { OwnerRegistrationDetailsPage } from './routes/protected/registrations/OwnerRegistrationDetailsPage';
import { RegistrationCompletePage } from './routes/protected/registrations/RegistrationCompletePage';
import { StatusPage } from './routes/protected/registrations/StatusPage';

const router = createBrowserRouter([
  {
    path: paths.home.path,
    element: <Root />,
    children: [
      { index: true, element: <Home /> },
      { path: paths.search.path, element: <SearchResultPage /> },
      { path: paths.registrationDetails.path, element: <RegistrationDetails /> },
      { path: paths.registrationDetailsParent.path, element: <RegistrationDetails /> },
      { path: paths.about.path, element: <About /> },
      { path: paths.contact.path, element: <Contact /> },
      { path: paths.privatePolicy.path, element: <PrivatePolicy /> },
      { path: paths.registrationDetailsFromQr.path, element: <SearchResultPage /> },
      {
        path: '/admin',
        element: <AdminPage />,
        children: [
          { path: paths.editDataAttributes.path, element: <EditDataAttributesPage /> },
          { path: paths.editCategories.path, element: <EditCategoriesPage /> },
          { path: paths.editCompanies.path, element: <EditCompaniesPage /> },
          { path: paths.editRegistrations.path, element: <EditRegistrationsPage /> },
          { path: paths.newRegistration.path, element: <NewAdminRegistrationPage /> },
          { path: paths.editPersons.path, element: <EditPersonsPage /> },
          { path: paths.editOwners.path, element: <EditOwnersPage /> },
          { path: paths.editUsers.path, element: <EditUsersPage /> },
          { path: paths.fortnox.path, element: <FortnoxStatusPage /> },
          { path: paths.editOwnership.path, element: <EditOwnershipPage /> },
          { path: paths.editFinancions.path, element: <EditFinancionsPage /> },
        ],
      },
      {
        //path: '/protected',
        element: <ProtectedRoutes />,
        children: [
          { path: paths.myProfile.path, element: <MyProfilePage /> },
          { path: paths.myRegistrationsChangeOfOwnershipComplete.path, element: <ChangeOfOwnershipCompletePage /> },
          { path: paths.myRegistrationsChangeOfOwnershipConfirmed.path, element: <ConfirmOfOwnershipCompletePage /> },
          { path: paths.financings.path, element: <FinancingsPage /> },
          { path: paths.leases.path, element: <LeasesPage /> },
          { path: paths.insurances.path, element: <InsurancesPage /> },
          { path: paths.myOwners.path, element: <MyOwnersPage /> },
          {
            path: '/mina-registreringar',
            element: <MyRegistrationsPage />,
            children: [
              { path: paths.myRegistrations.path, element: <OwnerOverviewPage /> },
              { path: paths.myRegistrationsNewRegistration.path, element: <NewRegistrationByOwnerPage /> },
              { path: paths.myRegistrationsCopyRegistration.path, element: <NewRegistrationByOwnerPage /> },
              //{ path: paths.myRegistrationsNewChildRegistration.path, element: <MyRegistrationsPage subPagePath={paths.myRegistrationsNewChildRegistration.path} /> },
              { path: paths.myRegistrationsRegistrationComplete.path, element: <RegistrationCompletePage /> },
              { path: paths.myRegistrationsChangeOfOwnership.path, element: <ChangeOfOwnershipPage /> },
              { path: paths.myRegistrationsRegistrations.path, element: <ConfirmedRegistrationsPage /> },
              { path: paths.myRegistrationsRegistrationDetails.path, element: <OwnerRegistrationDetailsPage /> },
              { path: paths.myRegistrationsStatus.path, element: <StatusPage /> },
              {
                path: paths.myRegistrationsChangeOfOwnershipRegistrations.path,
                element: <ChangeOfOwnerRegistrationsPage />,
              },
              {
                path: paths.myRegistrationsChangeOfOwnershipRegistrationsDetails.path,
                element: <OwnerRegistrationDetailsPage />,
              },
            ],
          },
        ],
      },
      { path: '*', element: <Navigation /> },
    ],
  },
  // { path: '*', element: <Root /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <AnalyticsWithConsent />
  </React.StrictMode>
);

function sendToAnalytics({ id, name, value }: { id: string; name: string; value: number }) {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    nonInteraction: true, // avoids affecting bounce rate
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);
